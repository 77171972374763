import React from "react"
import Link from "gatsby-link"
import { Container, Tabs, TabList, Tab, TabLink } from "bloomer"

const Header = () => (
  <Container>
    <Tabs isAlign="centered">
      <TabList>
        <Tab>
          <Link to="/">Home</Link>
        </Tab>
        <Tab>
          <Link to="/portfolio/">Portfolio</Link>
        </Tab>
      </TabList>
    </Tabs>
  </Container>
)

export default Header
